import { styled } from "@mui/material";
import { IconPlus } from "@veneer/core";
import PropTypes from "prop-types";
import React from "react";
import { NoDataBanner } from "../../images/loaders/NoDataBanner";
import VeneerButton from "./VeneerButton";

const NoDataDisplayCardRoot = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  padding: "16px",

  "& img": {
    paddingBottom: "16px",
  },

  "& p": {
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
  },

  "& span": {
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
  },
}));

const NoDataDisplayCard = ({ title, onAddClick }) => (
  <NoDataDisplayCardRoot>
    <NoDataBanner />
    <p>No data to display</p>
    {title && <span>Add {title} to display them here.</span>}
    <VeneerButton
      appearance="primary"
      leadingIcon={<IconPlus />}
      small
      style={{ marginTop: "20px" }}
      onClick={onAddClick}
    >
      Add
    </VeneerButton>
  </NoDataDisplayCardRoot>
);

NoDataDisplayCard.propTypes = {
  title: PropTypes.string,
  onAddClick: PropTypes.func,
};

export default NoDataDisplayCard;
