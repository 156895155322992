import { Grid, useTheme } from "@mui/material";
import { useToast } from "@veneer/core";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Form } from "react-final-form";
import { useDispatch, useSelector } from "react-redux";
import { useErrorHandling } from "../../admin-actions/pools/hooks/useErrorHandling";
import { getUsers } from "../../admin-actions/pools/poolsSlice";
import { useOrgAndTenantId } from "../../common/hooks/useOrgAndTenantId";
import { getTenantId } from "../../common/utils";
import { setDeviceImage } from "../onboardingSlice";
import { validateDeviceImageAdvanced } from "../onboardingUtils";
import { PersistentForm } from "./PersistentForm";

const UserAssignmentPolicyForm = React.forwardRef(
  (
    { onFormValidityChange, initialValues, setFormValues, onChange, onSubmit },
    ref,
  ) => {
    const dispatch = useDispatch();
    const { addToast } = useToast();
    const theme = useTheme();
    const { errorMessages, resetErrors } = useErrorHandling();

    const { organizationId } = useOrgAndTenantId();
    const selectedPartner = useSelector(
      (state) => state.partners.selectedPartner,
    );
    const tenantId = getTenantId(selectedPartner);
    useEffect(() => {
      dispatch(setDeviceImage(null));
    }, []);

    const onSelectionSubmit = (values) => {
      if (onSubmit) {
        onSubmit({ ...values });
      }
    };

    const handleChange = (value) => {
      setFormValues(value);
      if (onChange) {
        onChange(value);
      }
    };

    const [messagesAlreadyNotified, setMessagesAlreadyNotified] = useState([]);

    useEffect(() => {
      if (tenantId && organizationId) {
        dispatch(getUsers({ tenantId, organizationId, useCache: true }));
      }
    }, [tenantId, organizationId, dispatch]);

    useEffect(() => {
      if (errorMessages.length > 0) {
        const newMessages = errorMessages.filter(
          (item) => !messagesAlreadyNotified.includes(item),
        );
        if (newMessages.length > 0) {
          setMessagesAlreadyNotified((prevMessages) => [
            ...prevMessages,
            ...newMessages,
          ]);
          newMessages.map((message) =>
            addToast({
              text: message,
              type: "negative",
              timeout: 6,
              id: Date.now(),
            }),
          );
        }
      }
    }, [addToast, errorMessages, messagesAlreadyNotified, resetErrors]);

    return (
      <>
        <Grid container spacing={2}>
          <Grid item xs={12} sx={{ marginBottom: "20px" }}>
            <Form
              onSubmit={onSelectionSubmit}
              initialValues={initialValues}
              validate={(values) => {
                const valid = validateDeviceImageAdvanced({ ...values });
                if (onFormValidityChange) {
                  onFormValidityChange(valid);
                }
                return valid;
              }}
              render={(formMeta) => {
                return (
                  <form onSubmit={formMeta.handleSubmit} ref={ref}>
                    <Grid
                      container
                      spacing={1}
                      sx={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        gap: "16px",
                      }}
                    >
                      <Grid item xs={12} color={theme.palette.foreground.light}>
                        <p style={{ fontSize: "12px" }}>
                          Choose a policy based on the tenant&apos;s needs: use
                          a persistent assignment to keep a virtual desktop
                          assigned to a specific user, or a floating assignment
                          to dynamically reallocate virtual desktop based on
                          availability. Upon a user’s first login to a
                          persistent pool, they will be assigned to an available
                          machine, which will then be permanently their’s for
                          all future sessions.
                        </p>
                      </Grid>
                      <Grid item xs={12} color={theme.palette.foreground.light}>
                        <PersistentForm
                          initialValues={{
                            persistent: formMeta.initialValues?.persistent,
                            targetUsers: initialValues?.assignmentUser,
                            time: initialValues?.time,
                            entraGroups: initialValues?.entraGroups,
                          }}
                          onChange={handleChange}
                        />
                      </Grid>
                    </Grid>
                  </form>
                );
              }}
            />
          </Grid>
        </Grid>
      </>
    );
  },
);

UserAssignmentPolicyForm.displayName = "UserAssignmentPolicy";
UserAssignmentPolicyForm.propTypes = {
  onDeviceImageChange: PropTypes.func,
  currentStatus: PropTypes.bool,
  deviceImage: PropTypes.object,
  required: PropTypes.bool,
};
UserAssignmentPolicyForm.defaultProps = {
  currentStatus: false,
};

export default UserAssignmentPolicyForm;
