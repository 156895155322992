import { Grid, useTheme } from "@mui/material";
import { IconInfo } from "@veneer/core";
import PropTypes from "prop-types";
import React from "react";
import { Field, Form } from "react-final-form";
import { handleChange } from "../../admin-actions/pools/poolsUtils";
import CardExample from "../../common/components/CardExample";
import RadioButtons from "../../common/components/form/RadioButtons";
import TextInput from "../../common/components/form/TextInput";
import {
  composeValidators,
  greaterThanThree,
  required,
} from "../../common/validators";
import Tooltip from "../../common/components/Tooltip";

export default function MachineNamingPatternForm({
  onChange,
  dataCardsExamples,
  initialValues,
  form = "machineNamingPatternForm",
  options = [
    { value: "Sequential", label: "Sequential" },
    { value: "Random", label: "Random" },
  ],
}) {
  const theme = useTheme();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} color={theme.palette.foreground.light}>
        Choose a static prefix for the Virtual Machines (VM) and a numbering
        suffix.
      </Grid>
      <Grid item xs={12}>
        <Form
          onSubmit={() => {}}
          validate={(e) => onChange(e, form)}
          initialValues={initialValues}
          render={(formMeta) => {
            const form = formMeta.form;
            return (
              <form
                onSubmit={formMeta.handleSubmit}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "12px",
                }}
              >
                <Field
                  name="prefix"
                  validate={required}
                  render={({ input, meta }) => (
                    <TextInput
                      {...input}
                      label="Prefix"
                      sx={{
                        width: "100%",
                      }}
                      error={meta.touched && meta.error}
                      helperText={meta.touched && meta.error}
                    />
                  )}
                />
                <Field
                  name="suffixLength"
                  validate={composeValidators(required, greaterThanThree)}
                  render={({ input, meta }) => (
                    <TextInput
                      {...input}
                      type="number"
                      label="Suffix Length"
                      sx={{
                        width: "100%",
                      }}
                      error={meta.touched && meta.error}
                      helperText={meta.touched && meta.error}
                      trailingIcon={
                        <Tooltip
                          contentHideDelayOnHover={300}
                          contentShowDelayOnHover={300}
                          placement="trailing"
                          content={
                            <>Value should be greater than or equal to three</>
                          }
                        >
                          <IconInfo style={{ marginRight: "8px" }} />
                        </Tooltip>
                      }
                      min={1}
                    />
                  )}
                />
                <Field
                  name="suffixNumberingPattern"
                  validate={required}
                  render={() => (
                    <RadioButtons
                      alignment="horizontal"
                      onChange={(_, e) => {
                        handleChange("suffixNumberingPattern", e, form);
                      }}
                      options={options}
                      label="Suffix numbering pattern"
                      name="suffixNumberingPattern"
                      value={formMeta.values.suffixNumberingPattern}
                      defaultValue={
                        formMeta.initialValues.suffixNumberingPattern
                      }
                    />
                  )}
                />
                {dataCardsExamples && (
                  <CardExample
                    title="Sequential numbering example:"
                    examples={dataCardsExamples}
                  />
                )}
              </form>
            );
          }}
        />
      </Grid>
    </Grid>
  );
}

MachineNamingPatternForm.propTypes = {
  onChange: PropTypes.func.isRequired,
  dataCardsExamples: PropTypes.bool,
  initialValues: PropTypes.object,
  form: PropTypes.string,
  options: PropTypes.array,
};
