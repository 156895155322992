import { IconInfo, Tooltip as VeneerTooltip } from "@veneer/core";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";

const Tooltip = (props) => {
  const {
    children,
    id = "default-tooltip",
    placement = "bottom",
    icon = <IconInfo />,
    content,
    arrow = false,
    ...rest
  } = props;

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    return () => {
      // Cleanup on unmount
      setIsOpen(false);
    };
  }, []);

  const showTooltip = useCallback((e) => {
    e.stopPropagation();
    setIsOpen(true);
  }, []);

  const hideTooltip = useCallback((e) => {
    e.stopPropagation();
    setIsOpen(false);
  }, []);

  if (!content && !children) {
    return null;
  }

  return (
    <VeneerTooltip
      id={id}
      placement={placement}
      content={content}
      arrow={arrow}
      open={isOpen}
      {...rest}
    >
      <span
        role="tooltip"
        aria-describedby={id}
        onMouseEnter={showTooltip}
        onMouseLeave={hideTooltip}
        onFocus={showTooltip}
        onBlur={hideTooltip}
        onTouchStart={showTooltip}
        onTouchEnd={hideTooltip}
      >
        {children || icon}
      </span>
    </VeneerTooltip>
  );
};

Tooltip.propTypes = {
  children: PropTypes.node,
  id: PropTypes.string,
  placement: PropTypes.string,
  icon: PropTypes.node,
  content: PropTypes.node,
  arrow: PropTypes.bool,
};

export default Tooltip;
