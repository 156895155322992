import PropTypes from "prop-types";
import React from "react";
import { TagIndicator } from "./TagIndicator";

export const SessionHistoryHeaderTag = ({ label, prefix }) => {
  if (!label && !prefix) {
    return null;
  }

  return <TagIndicator label={`${prefix}: ${label}`} type={"primary"} />;
};

SessionHistoryHeaderTag.propTypes = {
  user: PropTypes.string,
};
