import { useTheme } from "@mui/material/styles";
import React from "react";

const GRAY_12 = "#212121";
const WHITE = "#FFFFFF";

export const PageNotFoundBanner = () => {
  const theme = useTheme();
  const primaryColor = theme.palette.primary.main;
  const blobColor = theme.palette.background.stroke;
  const highlightColor = theme.palette.primary.highlight;
  const foregroundColor = theme.palette.foreground.default;

  return (
    <svg
      width="284"
      height="247"
      viewBox="0 0 284 247"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Web Errors">
        <circle
          id="Background"
          cx="126"
          cy="120.344"
          r="120"
          fill={blobColor}
        />
        <g id="Hole">
          <ellipse
            id="Ellipse 395"
            cx="126.266"
            cy="196.631"
            rx="103.649"
            ry="14.4911"
            fill={WHITE}
            stroke={GRAY_12}
          />
          <path
            id="Ellipse 396"
            d="M227.631 199.693C217.338 193.115 175.754 188.174 126.13 188.174C76.5803 188.174 35.1343 193.08 24.7637 199.643C24.7637 199.643 41.1464 211.143 126.13 211.143C211.114 211.143 227.631 199.693 227.631 199.693Z"
            fill={GRAY_12}
            stroke={GRAY_12}
            strokeLinejoin="round"
          />
        </g>
        <g id="Signpost">
          <path
            id="Vector"
            d="M12.7561 64.6063L25.5894 58.3859C26.0528 58.1613 26.585 58.124 27.0752 58.282L49.1002 65.3796L50.7263 76.252L29.0757 77.3548C28.6634 77.3758 28.2547 77.2687 27.9057 77.0481L12.0819 67.0498C11.29 66.3785 11.5103 65.1288 12.4294 64.742C12.538 64.6962 12.65 64.6577 12.7561 64.6063Z"
            fill={WHITE}
            stroke={GRAY_12}
            strokeLinejoin="round"
          />
          <path
            id="Vector 2438"
            d="M48.9394 65.5036L47.7695 65.1694V76.5329L50.3765 76.299L48.9394 65.5036Z"
            fill={GRAY_12}
          />
          <rect
            id="Rectangle 3467549"
            x="47.4609"
            y="53.5049"
            width="19.5571"
            height="132.362"
            transform="rotate(-7.79254 47.4609 53.5049)"
            fill={WHITE}
            stroke={GRAY_12}
            strokeLinejoin="round"
          />
          <rect
            id="Rectangle 3467552"
            x="47.4609"
            y="53.5049"
            width="2.58628"
            height="132.362"
            transform="rotate(-7.79254 47.4609 53.5049)"
            fill={GRAY_12}
          />
          <path
            id="Vector_2"
            d="M59.5938 60.629L61.6115 76.8598L120.392 44.4915C120.714 44.3141 120.981 44.0512 121.163 43.7318L132.108 24.5772C132.712 23.716 132.17 22.5458 131.166 22.4121C131.057 22.3976 130.948 22.3898 130.84 22.3699L116.353 19.6936C115.81 19.5934 115.25 19.7219 114.805 20.0488L59.5938 60.629Z"
            fill={WHITE}
            stroke={GRAY_12}
            strokeLinejoin="round"
          />
          <path
            id="Vector 2440"
            d="M59.4614 60.6704L59.6177 60.6704L61.5918 77.1446L59.005 78.7695L59.4614 60.6704Z"
            fill={GRAY_12}
          />
          <path
            id="Vector 2441"
            d="M63.9907 84.4815L64.147 84.4814L65.4766 95.2917L62.7073 98.0122L63.9907 84.4815Z"
            fill={GRAY_12}
          />
          <path
            id="Vector_3"
            d="M64.1881 84.5661L65.5847 95.083L105.178 102.833C105.645 102.924 106.128 102.847 106.542 102.614L113.969 98.4435C114.146 98.3441 114.346 98.287 114.52 98.1834C114.974 97.9143 115.117 97.2914 114.795 96.8499C114.731 96.7624 114.643 96.696 114.554 96.6341L102.853 88.4981L64.1881 84.5661Z"
            fill={WHITE}
            stroke={GRAY_12}
            strokeLinejoin="round"
          />
          <path
            id="Vector_4"
            d="M69.9811 73.4853L71.3777 84.0021L98.9556 76.1453C99.2142 76.0717 99.4552 75.9466 99.6644 75.7777L106.87 69.9572C107.116 69.7587 107.433 69.6528 107.669 69.4423C107.942 69.1984 108.048 68.8046 107.93 68.4558C107.838 68.1817 107.555 68.0392 107.282 67.9433L97.7315 64.5877L69.9811 73.4853Z"
            fill={WHITE}
            stroke={GRAY_12}
            strokeLinejoin="round"
          />
          <path
            id="Vector 2439"
            d="M70.1422 73.4392L71.312 73.105L73.9629 83.4015L71.5514 84.0884L70.1422 73.4392Z"
            fill={GRAY_12}
          />
          <path
            id="Vector_5"
            d="M52.7396 92.5489L51.343 82.032L23.7651 89.8888C23.5065 89.9625 23.2655 90.0875 23.0563 90.2565L15.8506 96.077C15.6048 96.2755 15.2872 96.3814 15.0516 96.5919C14.7786 96.8358 14.6728 97.2296 14.7905 97.5784C14.8831 97.8525 15.1655 97.995 15.4385 98.0909L24.9892 101.446L52.7396 92.5489Z"
            fill={WHITE}
            stroke={GRAY_12}
            strokeLinejoin="round"
          />
          <path
            id="Vector 2437"
            d="M51.3127 82.248L50.2433 82.5696L50.0215 88.7728L51.8809 87.8295L51.3127 82.248Z"
            fill={GRAY_12}
          />
          <path
            id="Rectangle 3467550"
            d="M66.1064 63.0547C65.9741 62.2917 66.2941 61.5209 66.928 61.0761L104.549 34.6703C105.798 33.7933 107.53 34.5831 107.687 36.1015L108.32 42.2158C108.399 42.9862 108.027 43.7334 107.364 44.1337L69.5508 66.9639C68.3461 67.6913 66.7868 66.98 66.5465 65.5934L66.1064 63.0547Z"
            fill={highlightColor}
          />
          <path
            id="Vector_6"
            d="M53.6172 99.3456L52.1605 87.628L9.86196 110.92C9.53972 111.098 9.27266 111.361 9.09016 111.68L1.26657 125.373C0.839451 125.981 1.20556 126.804 1.90176 126.929C2.0093 126.948 2.11911 126.955 2.22655 126.975L12.4041 128.855C12.9469 128.955 13.5071 128.826 13.9519 128.499L53.6172 99.3456Z"
            fill={WHITE}
            stroke={GRAY_12}
            strokeLinejoin="round"
          />
          <path
            id="Vector 2436"
            d="M51.9815 87.6152L50.9121 88.2637L51.5927 100.704L53.407 99.6674L51.9815 87.6152Z"
            fill={GRAY_12}
          />
          <path
            id="Rectangle 3467551"
            d="M48.8562 97.2469C48.9885 98.0098 48.6684 98.7806 48.0346 99.2255L21.9386 117.542C20.6891 118.419 18.9573 117.629 18.8002 116.111L18.4774 112.99C18.3977 112.219 18.77 111.472 19.433 111.072L45.7333 95.1927C46.9381 94.4653 48.4973 95.1766 48.7377 96.5632L48.8562 97.2469Z"
            fill={highlightColor}
          />
        </g>
        <g id="Briefcase">
          <path
            id="Side"
            d="M37.1783 180.27C37.0345 179.174 37.8058 178.17 38.901 178.026L95.3057 170.622C96.4009 170.478 97.4052 171.25 97.549 172.345L102.589 210.736C78.0018 210.204 54.2981 208.112 40.3489 204.424L37.1783 180.27Z"
            fill={WHITE}
            stroke={GRAY_12}
            strokeLinejoin="round"
          />
          <path
            id="Front"
            d="M30.065 181.203C29.9212 180.108 30.6925 179.104 31.7877 178.96L88.1918 171.556C89.2872 171.412 90.2917 172.184 90.4352 173.279L95.309 210.493C70.4153 209.655 48.8122 207.369 32.9205 202.956L30.065 181.203Z"
            fill={WHITE}
            stroke={GRAY_12}
            strokeLinejoin="round"
          />
          <g id="Handle">
            <path
              id="Union"
              d="M51.2203 168C50.5809 168.084 50.1483 168.806 50.2541 169.612L50.3983 170.71C50.399 170.716 50.3997 170.721 50.4005 170.726L51.1477 176.419L54.3345 176.001L53.7767 171.751L68.0476 169.878L68.6054 174.127L71.7921 173.709L70.8985 166.902C70.7927 166.096 70.1886 165.51 69.5491 165.594L51.2203 168Z"
              fill={WHITE}
              stroke={GRAY_12}
              strokeLinejoin="round"
            />
            <path
              id="Union_2"
              d="M49.7398 168.194C49.1004 168.278 48.6678 169 48.7736 169.806L48.9178 170.904C48.9185 170.909 48.9192 170.915 48.92 170.92L49.6673 176.613L52.854 176.195L52.2962 171.945L66.5671 170.072L67.1249 174.321L70.3116 173.903L69.4181 167.096C69.3122 166.29 68.7081 165.704 68.0687 165.788L49.7398 168.194Z"
              fill={WHITE}
              stroke={GRAY_12}
              strokeLinejoin="round"
            />
          </g>
        </g>
        <g id="Sai" clipPath="url(#clip0_15470_68242)">
          <path
            id="Neck"
            d="M187.889 126.664C186.208 126.044 184.757 124.921 183.731 123.447C182.705 121.972 182.154 120.218 182.152 118.42V100.864L156.863 100.71C157.295 103.459 157.175 112.44 157.027 118.563C156.985 120.325 156.415 122.035 155.393 123.469C154.371 124.902 152.943 125.995 151.295 126.604L151.125 126.664C151.125 126.664 153.567 136.008 169.242 136.008C184.916 136.008 187.889 126.664 187.889 126.664Z"
            fill={WHITE}
            stroke={GRAY_12}
            strokeMiterlimit="10"
            strokeLinecap="round"
          />
          <g id="Head">
            <path
              id="Vector_7"
              d="M166.072 110.746C166.072 110.746 172.664 116.803 182.447 110.338L182.447 108.206L166.072 110.746Z"
              fill={GRAY_12}
            />
            <g id="Hair Back">
              <path
                id="Vector_8"
                d="M150.846 75.7016C155.529 77.6622 160.691 78.1573 165.654 77.122C173.739 75.3472 175.804 64.641 170.764 60.8347C165.724 57.0284 155.361 61.3739 152.221 66.2161C149.081 71.0583 149.171 73.0023 150.846 75.7016Z"
                fill={GRAY_12}
              />
              <path
                id="Vector_9"
                d="M174.693 65.9318L154.137 70.4438C149.346 71.4953 146.319 76.2462 147.376 81.0552L147.898 83.4334C148.955 88.2425 153.695 91.2886 158.485 90.2371L179.041 85.7251C183.832 84.6736 186.858 79.9227 185.802 75.1137L185.279 72.7355C184.223 67.9264 179.483 64.8803 174.693 65.9318Z"
                fill={GRAY_12}
              />
              <path
                id="Vector_10"
                d="M151.611 72.9515C152.368 72.4458 152.357 71.0953 151.587 69.9352C150.816 68.7751 149.577 68.2446 148.82 68.7503C148.063 69.2561 148.074 70.6065 148.844 71.7666C149.615 72.9267 150.854 73.4572 151.611 72.9515Z"
                fill={GRAY_12}
              />
              <path
                id="Vector_11"
                d="M151.8 72.9216C151.823 72.0084 150.716 71.2374 149.329 71.1996C147.941 71.1618 146.797 71.8714 146.774 72.7845C146.751 73.6977 147.857 74.4687 149.245 74.5065C150.633 74.5443 151.777 73.8347 151.8 72.9216Z"
                fill={GRAY_12}
              />
            </g>
            <g id="Vector_12">
              <path
                d="M166.062 110.408C169.515 111.739 173.281 112.014 176.886 111.2C188.768 108.592 186.656 92.6749 186.095 89.4836C185.534 86.2922 183.24 76.492 182.338 72.3852C181.436 68.2783 173.583 66.6707 166.406 68.246C159.23 69.8212 148.353 72.822 149.899 79.86L153.523 96.3517C154.169 99.0699 155.413 101.61 157.163 103.785"
                fill={WHITE}
              />
              <path
                d="M166.062 110.408C169.515 111.739 173.281 112.014 176.886 111.2C188.768 108.592 186.656 92.6749 186.095 89.4836C185.534 86.2922 183.24 76.492 182.338 72.3852C181.436 68.2783 173.583 66.6707 166.406 68.246C159.23 69.8212 148.353 72.822 149.899 79.86L153.523 96.3517C154.169 99.0699 155.413 101.61 157.163 103.786"
                stroke={GRAY_12}
                strokeMiterlimit="10"
                strokeLinecap="round"
              />
            </g>
            <path
              id="Vector_13"
              d="M175.84 79.8283C175.734 80.4536 177.16 80.7181 178.92 81.0086C180.679 81.2991 182.111 81.5087 182.206 80.8833C182.301 80.2578 181.046 79.0389 179.281 78.7428C177.516 78.4467 175.941 79.2028 175.84 79.8283Z"
              fill={GRAY_12}
            />
            <path
              id="Vector_14"
              d="M178.981 85.8058C179.72 85.6437 180.186 84.9115 180.023 84.1703C179.86 83.429 179.13 82.9595 178.392 83.1216C177.653 83.2837 177.187 84.0159 177.35 84.7571C177.512 85.4984 178.243 85.9679 178.981 85.8058Z"
              fill={GRAY_12}
            />
            <path
              id="Vector_15"
              d="M159.16 86.7807C159.547 87.2859 160.711 86.4258 162.122 85.3371C163.532 84.2484 164.664 83.3438 164.279 82.8433C163.894 82.3429 162.129 82.4343 160.723 83.5202C159.317 84.6061 158.789 86.272 159.16 86.7807Z"
              fill={GRAY_12}
            />
            <path
              id="Vector_16"
              d="M165.141 89.3556C165.88 89.1935 166.346 88.4613 166.183 87.7201C166.021 86.9788 165.29 86.5093 164.552 86.6714C163.813 86.8335 163.347 87.5657 163.51 88.3069C163.673 89.0482 164.403 89.5177 165.141 89.3556Z"
              fill={GRAY_12}
            />
            <path
              id="Vector_17"
              d="M175.053 99.2632C175.053 99.2632 173.859 98.8041 170.848 99.2632"
              stroke={GRAY_12}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              id="Hair Front"
              d="M152.739 76.9683C159.494 78.8987 166.619 79.1158 173.472 77.6001C184.953 75.08 188.564 62.9653 181.726 59.0414C174.889 55.1175 160.036 60.6623 155.288 66.2739C150.54 71.8855 150.559 74.0647 152.739 76.9683Z"
              fill={GRAY_12}
            />
            <g id="Vector_18">
              <path
                d="M173.446 85.1072C175.035 89.4571 178.153 92.0141 176.298 92.7588C175.087 93.2244 173.817 93.5238 172.525 93.6489"
                fill={WHITE}
              />
              <path
                d="M173.446 85.1072C175.035 89.4571 178.153 92.0141 176.298 92.7588C175.087 93.2244 173.817 93.5238 172.525 93.6489"
                stroke={GRAY_12}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <path
              id="Vector_19"
              d="M170.141 94.4088L176.911 92.9227C177.248 92.8488 177.597 92.8421 177.936 92.9033C178.276 92.9644 178.601 93.0921 178.892 93.2791C179.183 93.4661 179.434 93.7086 179.632 93.9929C179.83 94.2773 179.97 94.5978 180.044 94.9362L180.322 96.2031L168.419 98.8159L168.14 97.549C167.99 96.8656 168.117 96.1508 168.492 95.5619C168.867 94.973 169.46 94.5582 170.141 94.4088Z"
              fill={GRAY_12}
            />
            <g id="Ear">
              <g id="Vector_20">
                <path
                  d="M155.867 89.5733C155.374 88.8314 154.672 88.2526 153.852 87.91C153.031 87.5675 152.128 87.4766 151.257 87.6488C150.386 87.8211 149.586 88.2487 148.958 88.8777C148.33 89.5067 147.903 90.3088 147.729 91.1825C147.556 92.0563 147.645 92.9624 147.985 93.7864C148.324 94.6104 148.9 95.3153 149.638 95.8118C150.377 96.3084 151.245 96.5743 152.133 96.5761C153.021 96.5778 153.889 96.3152 154.628 95.8216"
                  fill={WHITE}
                />
                <path
                  d="M155.867 89.5733C155.374 88.8314 154.672 88.2526 153.852 87.91C153.031 87.5675 152.128 87.4766 151.257 87.6488C150.386 87.8211 149.586 88.2487 148.958 88.8777C148.33 89.5067 147.903 90.3088 147.729 91.1825C147.556 92.0563 147.645 92.9624 147.985 93.7864C148.324 94.6104 148.9 95.3153 149.638 95.8118C150.377 96.3084 151.245 96.5743 152.133 96.5761C153.021 96.5778 153.889 96.3152 154.628 95.8216"
                  stroke={GRAY_12}
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                />
              </g>
              <g id="Vector_21">
                <path
                  d="M150.918 90.9297C151.452 90.9107 151.98 91.0572 152.429 91.3491C152.878 91.6411 153.227 92.0645 153.429 92.5619"
                  fill={WHITE}
                />
                <path
                  d="M150.918 90.9297C151.452 90.9107 151.98 91.0572 152.429 91.3491C152.878 91.6411 153.227 92.0645 153.429 92.5619"
                  stroke={GRAY_12}
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                />
              </g>
              <path
                id="Vector_22"
                d="M152.461 91.3679L151.647 92.8182"
                stroke={GRAY_12}
                strokeMiterlimit="10"
                strokeLinecap="round"
              />
            </g>
            <g id="Glasses">
              <path
                id="Vector_23"
                d="M182.762 91.498C186.678 90.6384 189.152 86.7545 188.289 82.823C187.425 78.8916 183.55 76.4014 179.634 77.2609C175.718 78.1205 173.243 82.0044 174.107 85.9359C174.971 89.8673 178.846 92.3576 182.762 91.498Z"
                stroke={primaryColor}
                strokeWidth="2"
                strokeMiterlimit="10"
              />
              <path
                id="Vector_24"
                d="M169.725 85.5361L173.569 84.6922"
                stroke={primaryColor}
                strokeWidth="2"
                strokeMiterlimit="10"
              />
              <path
                id="Vector_25"
                d="M155.974 86.6578L151.621 87.6133"
                stroke={primaryColor}
                strokeWidth="2"
                strokeMiterlimit="10"
                strokeLinecap="round"
              />
              <path
                id="Vector_26"
                d="M164.6 95.4843C168.516 94.6247 170.99 90.7408 170.126 86.8094C169.263 82.8779 165.388 80.3877 161.472 81.2473C157.556 82.1069 155.081 85.9908 155.945 89.9222C156.809 93.8537 160.683 96.3439 164.6 95.4843Z"
                stroke={primaryColor}
                strokeWidth="2"
                strokeMiterlimit="10"
              />
            </g>
          </g>
          <g id="Shirt">
            <path
              id="Vector_27"
              d="M148.381 144.405C156.262 140.442 160.845 133.612 158.618 129.15C156.392 124.688 148.198 124.284 140.318 128.247C132.437 132.211 127.854 139.041 130.08 143.503C132.307 147.964 140.5 148.369 148.381 144.405Z"
              fill={primaryColor}
            />
            <path
              id="Vector_28"
              d="M208.689 143.491C210.915 139.029 206.332 132.199 198.451 128.236C197.611 127.813 198.075 125.814 197.236 125.491C194.437 126.667 191.218 126.948 187.902 125.614C184.321 125.805 181.312 126.811 180.151 129.138C177.924 133.6 182.508 140.43 190.388 144.393C198.269 148.357 206.462 147.953 208.689 143.491Z"
              fill={primaryColor}
            />
            <path
              id="Turtleneck"
              d="M155.687 118.925C155.657 120.312 155.239 121.662 154.481 122.822C153.723 123.981 152.655 124.903 151.4 125.482C151.039 125.639 150.733 125.902 150.524 126.235L150.294 126.62C150.136 126.877 150.043 127.167 150.021 127.468C149.999 127.768 150.049 128.069 150.168 128.346C151.263 130.907 155.522 137.761 169.248 137.761C182.974 137.761 187.606 130.852 188.816 128.352C188.941 128.095 189.006 127.813 189.006 127.527C189.006 127.241 188.941 126.959 188.816 126.703L188.673 126.417C188.469 126.014 188.133 125.693 187.721 125.51C186.464 124.899 185.403 123.946 184.66 122.76C183.916 121.573 183.52 120.201 183.516 118.799V118.249C183.514 117.678 183.288 117.131 182.886 116.727C182.484 116.324 181.938 116.096 181.37 116.095L181.184 116.139C173.506 117.894 165.531 117.879 157.86 116.095C157.299 116.096 156.76 116.317 156.359 116.712C155.959 117.106 155.727 117.642 155.714 118.205L155.687 118.925Z"
              fill={primaryColor}
            />
            <path
              id="Shirt chest"
              d="M187.464 125.57C187.464 125.57 188.263 131.957 169.396 131.957C150.529 131.957 151.329 125.57 151.329 125.57C151.329 125.57 141.436 126.966 141.436 141.647V211.284C161.717 210.959 181.425 209.769 197.39 207.554V141.647C197.39 126.966 187.464 125.57 187.464 125.57Z"
              fill={primaryColor}
            />
            <g id="Shirt details">
              <path
                id="Vector_29"
                d="M162.645 119.146V121.96"
                stroke={highlightColor}
                strokeMiterlimit="10"
                strokeLinecap="round"
              />
              <path
                id="Vector_30"
                d="M166.072 119.58V122.35"
                stroke={highlightColor}
                strokeMiterlimit="10"
                strokeLinecap="round"
              />
              <path
                id="Vector_31"
                d="M169.504 119.711V122.751"
                stroke={highlightColor}
                strokeMiterlimit="10"
                strokeLinecap="round"
              />
              <path
                id="Vector_32"
                d="M172.932 119.58V122.35"
                stroke={highlightColor}
                strokeMiterlimit="10"
                strokeLinecap="round"
              />
              <path
                id="Vector_33"
                d="M176.359 119.146V121.96"
                stroke={highlightColor}
                strokeMiterlimit="10"
                strokeLinecap="round"
              />
              <path
                id="Vector_34"
                d="M179.791 118.7V121.267"
                stroke={highlightColor}
                strokeMiterlimit="10"
                strokeLinecap="round"
              />
              <path
                id="Vector_35"
                d="M182.157 124.235C182.157 124.235 177.947 125.565 169.51 125.565C161.073 125.565 156.857 124.235 156.857 124.235"
                stroke={highlightColor}
                strokeMiterlimit="10"
                strokeLinecap="round"
              />
              <path
                id="Vector_36"
                d="M159.211 118.7V121.267"
                stroke={highlightColor}
                strokeMiterlimit="10"
                strokeLinecap="round"
              />
            </g>
          </g>
          <g id="Arm">
            <path
              id="Vector_37"
              d="M238.941 105.778L248.668 124.56L197.107 160.122L191.44 128.22L238.941 105.778Z"
              fill={primaryColor}
            />
            <path
              id="Vector_38"
              d="M210.712 148.083C214.18 140.619 210.912 131.746 203.412 128.264C195.912 124.781 187.02 128.009 183.552 135.473C180.083 142.937 183.351 151.811 190.851 155.293C198.352 158.775 207.243 155.547 210.712 148.083Z"
              fill={primaryColor}
            />
            <path
              id="Vector_39"
              d="M197.383 159.931L197.369 142.138L199.077 158.76L197.383 159.931Z"
              fill={GRAY_12}
            />
            <g id="Mug">
              <path
                id="Vector_40"
                d="M191.754 157.337V154.727C191.754 154.249 191.92 153.791 192.215 153.454C192.511 153.116 192.912 152.926 193.33 152.926H208.369C208.787 152.926 209.188 153.116 209.484 153.454C209.779 153.791 209.945 154.249 209.945 154.727V175.258C209.945 175.736 209.779 176.194 209.484 176.531C209.188 176.869 208.787 177.059 208.369 177.059H193.322C192.904 177.059 192.503 176.869 192.207 176.531C191.912 176.194 191.746 175.736 191.746 175.258V172.558C190.849 172.593 189.956 172.422 189.118 172.054C188.281 171.687 187.517 171.13 186.872 170.419C186.227 169.707 185.714 168.854 185.363 167.911C185.013 166.968 184.832 165.954 184.832 164.929C184.832 163.905 185.013 162.891 185.363 161.948C185.714 161.005 186.227 160.152 186.872 159.44C187.517 158.728 188.281 158.172 189.118 157.804C189.956 157.437 190.849 157.266 191.746 157.301L191.754 157.337Z"
                fill={WHITE}
                stroke={GRAY_12}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                id="Vector_41"
                d="M191.754 168.237C191.365 168.261 190.976 168.193 190.611 168.039C190.245 167.886 189.911 167.648 189.629 167.342C189.346 167.036 189.121 166.667 188.968 166.259C188.814 165.85 188.735 165.41 188.735 164.965C188.735 164.521 188.814 164.081 188.968 163.672C189.121 163.263 189.346 162.895 189.629 162.589C189.911 162.283 190.245 162.045 190.611 161.891C190.976 161.738 191.365 161.67 191.754 161.693V168.237Z"
                fill={WHITE}
                stroke={GRAY_12}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                id="Vector_42"
                d="M194.052 171.284V153.291H192.907C192.656 153.291 192.416 153.43 192.238 153.677C192.061 153.924 191.961 154.26 191.961 154.609V175.687V175.74C191.961 176.09 192.061 176.425 192.238 176.672C192.416 176.919 192.656 177.058 192.907 177.058C192.907 177.058 208.14 177.058 208.391 177.058C208.642 177.058 208.883 176.919 209.06 176.672C209.238 176.425 209.945 176.09 209.945 175.74V174.29H196.215C195.931 174.291 195.65 174.213 195.388 174.063C195.125 173.912 194.887 173.691 194.686 173.412C194.485 173.132 194.326 172.801 194.217 172.436C194.108 172.07 194.052 171.679 194.052 171.284Z"
                fill={GRAY_12}
                stroke={GRAY_12}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                id="Vector 2428"
                d="M192.455 172.455L192.455 157.099C192.455 157.099 185.548 156.668 186.052 165.119C186.556 173.569 192.455 172.455 192.455 172.455Z"
                fill={GRAY_12}
              />
            </g>
            <g id="Forearm">
              <g id="Hand">
                <g id="Vector_43">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M187.415 79.9805L186.502 79.859C186.525 79.8635 186.549 79.8679 186.572 79.8721C186.874 79.9269 187.156 79.9637 187.415 79.9805Z"
                    fill={WHITE}
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M194.143 82.4857C194.143 82.4857 194.135 82.3375 192.21 82.5303C189.031 82.8486 188.61 83.445 185.45 82.7188C182.289 81.9925 180.902 79.0319 186.502 79.859C183.376 79.2667 178.372 76.7791 177.779 74.3917C177.777 74.3853 177.776 74.379 177.774 74.3727C177.762 74.3205 177.752 74.2684 177.743 74.2164C177.7 73.9406 177.718 73.6668 177.807 73.3982C178.765 70.5094 190.049 73.1724 197.231 74.5119C199.597 74.953 207.233 78.6348 207.233 78.6348L202.36 87.7102C202.36 87.7102 198.42 84.96 194.143 82.4857ZM186.502 79.859C186.502 79.859 186.502 79.859 186.502 79.859V79.859ZM186.502 79.859C186.502 79.859 186.502 79.859 186.502 79.859V79.859ZM187.415 79.9805L186.502 79.859C186.525 79.8635 186.549 79.8679 186.572 79.8721C186.874 79.9269 187.156 79.9637 187.415 79.9805Z"
                    fill={WHITE}
                  />
                  <path
                    d="M186.502 79.859C180.902 79.0319 182.289 81.9925 185.45 82.7188C188.61 83.445 189.031 82.8486 192.21 82.5303C194.135 82.3375 194.143 82.4857 194.143 82.4857C198.42 84.96 202.36 87.7102 202.36 87.7102L207.233 78.6348C207.233 78.6348 199.597 74.953 197.231 74.5119C190.049 73.1724 178.765 70.5094 177.807 73.3982C177.718 73.6668 177.7 73.9406 177.743 74.2164C177.752 74.2684 177.762 74.3205 177.774 74.3727C177.776 74.379 177.777 74.3853 177.779 74.3917M186.502 79.859L187.415 79.9805C187.156 79.9637 186.874 79.9269 186.572 79.8721M186.502 79.859C186.525 79.8635 186.549 79.8679 186.572 79.8721M186.502 79.859C183.376 79.2667 178.372 76.7791 177.779 74.3917M186.502 79.859C186.502 79.859 186.502 79.859 186.502 79.859ZM186.572 79.8721C183.452 79.3043 178.376 76.7971 177.779 74.3917"
                    stroke={GRAY_12}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
              </g>
              <path
                id="Vector_44"
                d="M248.94 108.316L233.984 125.643L201.007 94.699L213.128 80.6498L248.94 108.316Z"
                fill={primaryColor}
              />
              <path
                id="Vector_45"
                d="M251.562 119.822C253.569 113.836 250.32 107.35 244.305 105.335C238.289 103.32 231.786 106.539 229.779 112.526C227.772 118.512 231.021 124.999 237.036 127.014C243.052 129.028 249.555 125.809 251.562 119.822Z"
                fill={primaryColor}
              />
              <path
                id="Vector_46"
                d="M196.043 86.2149L203.963 77.0359C204.238 76.7167 204.574 76.4548 204.952 76.2651C205.329 76.0754 205.74 75.9616 206.162 75.9303C206.584 75.8989 207.008 75.9506 207.41 76.0824C207.812 76.2142 208.185 76.4235 208.506 76.6984L213.125 80.6523L201.01 94.6926L196.391 90.7386C196.07 90.4637 195.806 90.1287 195.615 89.7526C195.423 89.3765 195.308 88.9667 195.276 88.5467C195.244 88.1266 195.295 87.7045 195.426 87.3044C195.558 86.9043 195.767 86.5341 196.043 86.2149Z"
                fill={primaryColor}
              />
              <path
                id="Vector_47"
                d="M193.342 81.9444L199.323 75.0135C199.703 74.5735 200.243 74.302 200.824 74.2588C201.406 74.2156 201.981 74.4042 202.424 74.7831L204.431 76.5011L195.594 86.742L193.587 85.0239C193.146 84.6463 192.872 84.1103 192.827 83.5332C192.781 82.9561 192.966 82.3849 193.342 81.9444Z"
                fill={highlightColor}
              />
              <path
                id="Vector_48"
                d="M216.117 89.8643C217.321 84.8911 214.246 79.8795 209.249 78.6706C204.252 77.4617 199.225 80.5132 198.02 85.4864C196.816 90.4596 199.891 95.4712 204.888 96.6802C209.886 97.8891 214.913 94.8376 216.117 89.8643Z"
                fill={primaryColor}
              />
            </g>
          </g>
          <g id="Arm_2">
            <path
              id="Vector_49"
              d="M127.015 194.836L107.379 183.1L130.149 137.122L155.745 152.423L127.015 194.836Z"
              fill={primaryColor}
            />
            <path
              id="Vector_50"
              d="M138.498 159.063C146.36 161.51 154.726 157.096 157.184 149.204C159.643 141.312 155.263 132.93 147.402 130.484C139.541 128.037 131.175 132.451 128.716 140.343C126.257 148.235 130.637 156.616 138.498 159.063Z"
              fill={primaryColor}
            />
            <g id="Forearm_2">
              <g id="Hand_2">
                <g id="Hand_3">
                  <path
                    d="M145.261 175.74L148.791 186.628C148.791 186.628 169.308 178.612 177.199 177.272C177.713 177.184 179.884 176.962 180.74 176.862C183.827 176.501 186.09 175.246 188.264 173.072C189.315 172.022 189.245 171.308 189.245 171.308C191.108 171 191.808 171.084 191.808 168.617C190.892 168.809 190.669 168.898 189.54 169.072C192.49 168.617 192.207 168.171 192.207 166.933C192.207 166.19 191.578 166.106 190.399 166.264C191.309 166.104 192.197 165.841 192.207 165.235C192.231 163.85 192.092 163.383 191.717 163.247C191.607 163.207 191.15 163.238 190.589 163.297C191.269 163.182 191.431 163.076 192.092 162.944C192.092 161.523 192.207 160.034 191.717 159.913C190.124 159.518 186.883 159.39 184.264 159.763C181.644 160.135 178.821 168.322 174.503 169.261C166.086 171.093 155.913 173.39 145.261 175.74Z"
                    fill={WHITE}
                  />
                  <path
                    d="M188.019 163.561C191.288 163.321 190.965 163.168 192.092 162.944C192.092 161.523 192.207 160.034 191.717 159.913C190.124 159.518 186.883 159.39 184.264 159.763C181.644 160.135 178.821 168.322 174.503 169.261C166.086 171.093 155.913 173.39 145.261 175.74L148.791 186.628C148.791 186.628 169.308 178.612 177.199 177.272C177.713 177.184 179.884 176.962 180.74 176.862C183.827 176.501 186.09 175.246 188.264 173.072C189.315 172.022 189.245 171.308 189.245 171.308C191.108 171 191.808 171.084 191.808 168.617C190.892 168.809 190.669 168.898 189.54 169.072C192.49 168.617 192.207 168.171 192.207 166.933C192.207 165.889 190.963 166.147 188.697 166.548C189.524 166.305 192.189 166.287 192.207 165.235C192.231 163.85 192.092 163.383 191.717 163.247C191.342 163.111 187.001 163.797 188.019 163.561ZM188.019 163.561C188.214 163.515 187.354 163.609 188.019 163.561Z"
                    stroke={GRAY_12}
                    strokeLinejoin="round"
                  />
                </g>
              </g>
              <path
                id="Vector_51"
                d="M120.922 197.241L115.863 174.938L162.921 166.435L167.018 184.514L120.922 197.241Z"
                fill={primaryColor}
              />
              <path
                id="Vector_52"
                d="M128.754 181.135C126.027 175.44 119.178 173.043 113.455 175.781C107.733 178.518 105.304 185.354 108.031 191.049C110.758 196.744 117.608 199.142 123.33 196.404C129.053 193.666 131.481 186.83 128.754 181.135Z"
                fill={primaryColor}
              />
              <path
                id="Vector_53"
                d="M171.871 167.737L174.582 179.597C174.773 180.43 174.623 181.306 174.166 182.031C173.71 182.756 172.983 183.272 172.147 183.464L166.103 184.773L162.062 166.606L168.004 165.316C168.418 165.221 168.847 165.209 169.265 165.279C169.684 165.35 170.084 165.502 170.443 165.726C170.802 165.951 171.113 166.245 171.358 166.59C171.603 166.934 171.777 167.324 171.871 167.737Z"
                fill={primaryColor}
              />
              <path
                id="Vector_54"
                d="M176.947 168.385L178.97 177.305C179.098 177.871 178.995 178.466 178.684 178.958C178.373 179.451 177.879 179.8 177.31 179.93L174.732 180.519L171.743 167.339L174.321 166.75C174.888 166.621 175.482 166.72 175.974 167.026C176.466 167.333 176.816 167.821 176.947 168.385Z"
                fill={highlightColor}
              />
            </g>
            <path
              id="Vector_55"
              d="M143.941 169.867L154.188 154.745L148.344 169.076L143.941 169.867Z"
              fill={GRAY_12}
            />
            <path
              id="Vector_56"
              d="M141.367 191.622L168.589 184.146L141.367 196.079V191.622Z"
              fill={GRAY_12}
            />
          </g>
        </g>
        <g id="Question marks">
          <g id="Help">
            <path
              id="Vector_57"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M163.403 43.1756C163.687 44.4751 162.864 45.7592 161.565 46.0436C160.265 46.328 158.981 45.5051 158.697 44.2056C158.412 42.906 159.235 41.622 160.535 41.3375C161.834 41.0531 163.118 41.876 163.403 43.1756Z"
              fill={foregroundColor}
            />
            <path
              id="Vector_58"
              d="M148.164 26.673C148.087 24.2657 149.571 20.0734 154.373 19.0224C157.194 18.405 159.358 19.1122 160.881 20.4387C162.349 21.7179 163.12 23.4819 163.425 24.8786C163.942 27.2391 162.972 29.1907 161.976 30.6814C161.646 31.1757 161.274 31.6736 160.941 32.1182C160.786 32.3258 160.639 32.5221 160.51 32.7006C160.062 33.3181 159.781 33.774 159.654 34.1447C159.493 34.6161 159.405 34.891 159.383 35.3078C159.36 35.7512 159.412 36.4147 159.669 37.5891"
              stroke={foregroundColor}
              strokeWidth="2.18182"
              strokeLinecap="round"
            />
          </g>
          <g id="Help_2">
            <path
              id="Vector_59"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M203.656 58.9415C202.741 59.9073 201.216 59.9487 200.25 59.0338C199.285 58.119 199.243 56.5945 200.158 55.6286C201.073 54.6628 202.597 54.6215 203.563 55.5363C204.529 56.4511 204.57 57.9757 203.656 58.9415Z"
              fill={foregroundColor}
            />
            <path
              id="Vector_60"
              d="M208.736 37.0531C210.684 35.6359 214.985 34.5065 218.554 37.8868C220.651 39.8727 221.283 42.0602 221.041 44.065C220.809 45.9982 219.784 47.6276 218.8 48.6656C217.139 50.4199 214.979 50.715 213.187 50.7296C212.592 50.7344 211.971 50.7065 211.417 50.6815C211.158 50.6698 210.913 50.6588 210.692 50.6521C209.93 50.6289 209.395 50.6527 209.017 50.7563C208.537 50.888 208.26 50.9696 207.903 51.1858C207.523 51.4159 207.004 51.8324 206.177 52.7052"
              stroke={foregroundColor}
              strokeWidth="2.18182"
              strokeLinecap="round"
            />
          </g>
          <g id="Help_3">
            <path
              id="Vector_61"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M185.417 46.4434C184.873 47.6584 183.448 48.2025 182.233 47.6587C181.018 47.115 180.474 45.6893 181.017 44.4743C181.561 43.2593 182.987 42.7152 184.202 43.259C185.417 43.8027 185.961 45.2284 185.417 46.4434Z"
              fill={foregroundColor}
            />
            <path
              id="Vector_62"
              d="M182.221 17.1574C184.128 14.3943 189.265 10.9232 195.52 13.7224C199.194 15.3669 201.035 17.9527 201.643 20.701C202.23 23.3513 201.633 25.9678 200.819 27.7871C199.443 30.8617 196.739 32.2465 194.388 33.0931C193.608 33.3738 192.778 33.6237 192.037 33.8468C191.691 33.951 191.364 34.0495 191.071 34.1425C190.057 34.4638 189.364 34.7421 188.915 35.0528C188.344 35.4478 188.017 35.6829 187.648 36.1321C187.254 36.6101 186.763 37.3976 186.079 38.9272"
              stroke={foregroundColor}
              strokeWidth="2.18182"
              strokeLinecap="round"
            />
          </g>
        </g>
      </g>
      <defs>
        <clipPath id="clip0_15470_68242">
          <rect
            width="195.904"
            height="212.198"
            fill={WHITE}
            transform="translate(87.3184 34.4204)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
