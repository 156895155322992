import { zodResolver } from "@hookform/resolvers/zod";
import { Box, Grid, Typography, useTheme } from "@mui/material";
import { InlineNotification, Select } from "@veneer/core";
import PropTypes from "prop-types";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";
import TextInput from "../../common/components/form/TextInput";
import { DEFAULT_HOLDING_TIME } from "../../common/constants";
import {
  UNIT_OF_TIME_OPTIONS,
  validationHoldingTime,
} from "../../common/utils";

const holdingTimeSchema = z.object({
  time: z.number().min(1, "Time is required"),
  unit: z.string().min(1, "Unit is required"),
});

export default function PoolHoldingTimeForm({
  title,
  onChange,
  initialValues = DEFAULT_HOLDING_TIME,
  loading = false,
  description,
}) {
  const theme = useTheme();
  const { control, handleSubmit, watch } = useForm({
    mode: "all",
    defaultValues: {
      time: initialValues.time,
      unit: {
        label: initialValues.unit,
        value: initialValues.unit,
      },
    },
    resolver: zodResolver(holdingTimeSchema),
  });

  const formValues = watch();

  const internalOnChange = (value, type) => {
    const newValue = { ...formValues, [type]: value };
    if (onChange) {
      const convertedValue = {
        time: Number(newValue.time),
        unit: newValue.unit.value || newValue.unit,
      };
      onChange(convertedValue);
    }
  };

  const validation = !validationHoldingTime(
    formValues.time,
    formValues.unit.value,
  );

  return (
    <Grid container spacing={2}>
      {(description || title) && (
        <Grid item xs={12}>
          {title && (
            <Typography
              component="h3"
              variant="h3"
              sx={{
                color: theme.palette.foreground.default,
                fontSize: "18px",
                fontWeight: 500,
                lineHeight: "24px",
              }}
            >
              {title}
            </Typography>
          )}
          {description && (
            <p style={{ color: theme.palette.foreground.light }}>
              {description}
            </p>
          )}
        </Grid>
      )}
      <Grid
        item
        xs={12}
        sx={{
          "& label": {
            color: theme.palette.foreground.light,
          },
        }}
      >
        <form onSubmit={handleSubmit(() => {})}>
          <Box
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              gap: "12px",
              "& label": {
                textTransform: "capitalize",
              },
              "& > div": {
                width: "50%",
              },
            }}
          >
            <Controller
              name="time"
              control={control}
              render={({ field }) => (
                <TextInput
                  type="number"
                  min="1"
                  label="Holding time"
                  {...field}
                  onChange={(e) => {
                    field.onChange(e);
                    internalOnChange(Number(e || 0), "time");
                  }}
                />
              )}
            />
            <Controller
              name="unit"
              control={control}
              render={({ field }) => (
                <div>
                  <p>Unit of time</p>
                  <Select
                    style={{ minWidth: "50px" }}
                    onChange={(e) => {
                      field.onChange(e);
                      internalOnChange(e.value, "unit");
                    }}
                    value={[field.value.value]}
                    options={UNIT_OF_TIME_OPTIONS}
                    loading={loading}
                    clearIcon={false}
                    defaultValue={UNIT_OF_TIME_OPTIONS[0]}
                  />
                </div>
              )}
            />
          </Box>
          {validation && (
            <InlineNotification
              closeButton={false}
              customStyle={{
                width: "100%",
                marginTop: "15px",
              }}
              title="The minimum holding time allowed is 5 minutes and maximum is 3 years."
              type="negative"
            />
          )}
        </form>
      </Grid>
    </Grid>
  );
}

PoolHoldingTimeForm.propTypes = {
  onChange: PropTypes.func,
  initialValues: PropTypes.object,
  options: PropTypes.array,
  form: PropTypes.string,
  loading: PropTypes.bool,
  label: PropTypes.string,
};
