import { Grid } from "@mui/material";
import { Group, IconWarningAlt, Modal } from "@veneer/core";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { generateRandomId } from "../utils";
import VeneerButton from "./VeneerButton";
import TextInput from "./form/TextInput";

const VeneerModal = React.memo((props) => {
  const {
    customId,
    title,
    icon1Type,
    description1,
    description2,
    descriptionList = [],
    onClose,
    open,
    onConfirm,
    showSecondary,
    secondaryText,
    showPrimary,
    primaryText,
    manualReverify,
    verificationString,
    showHTMLContent,
    htmlContent,
    verificationHeading,
    primaryLoading,
    destructive,
    children,
    hasHTMLDescription,
    maxWidth,
  } = props;
  const [id] = useState(customId || generateRandomId("veneer-modal"));
  const [userVerificationString, setUserVerificationString] = useState("");

  if (!open) {
    return;
  }

  const isWarning = icon1Type === "warning";
  const isNormal = !isWarning && !destructive;
  const colorNotNormal = isWarning ? "warning" : "negative";
  const buttonColor = isNormal ? undefined : colorNotNormal;

  const truncatedVerificationString = verificationString?.trim();

  return (
    <Modal
      style={{ zIndex: 1400 }}
      align="start"
      closeButton={!!onClose}
      id={id}
      show={open}
      onClose={() => {
        setUserVerificationString("");
        onClose();
      }}
      maxWidth={maxWidth}
      className="ModalVeneerCustom"
      footer={
        primaryText ? (
          <Group>
            <VeneerButton
              onClick={async () => {
                await onConfirm(true);

                setUserVerificationString("");
              }}
              disabled={
                primaryLoading ||
                !showPrimary ||
                (manualReverify &&
                  userVerificationString.trim() !==
                    truncatedVerificationString) ||
                props.disablePrimary
              }
              colorScheme={buttonColor}
              loading={primaryLoading}
              data-testid="confirm-delete-button"
            >
              {primaryText}
            </VeneerButton>

            {showSecondary && (
              <VeneerButton
                appearance="secondary"
                onClick={() => {
                  onConfirm(false);
                  setUserVerificationString("");
                }}
              >
                {secondaryText}
              </VeneerButton>
            )}
          </Group>
        ) : undefined
      }
      title={title}
    >
      {showHTMLContent && htmlContent()}

      <Grid container alignItems="center">
        {isWarning && (
          <Grid item mr={1}>
            <IconWarningAlt />
          </Grid>
        )}

        <Grid item>
          {hasHTMLDescription && (
            <p dangerouslySetInnerHTML={{ __html: description1 }} />
          )}

          {!hasHTMLDescription && <p>{description1}</p>}
        </Grid>
      </Grid>

      {descriptionList.length > 0 && (
        <ul>
          {descriptionList.map((i, index) => (
            <li key={i + index}>{i}</li>
          ))}
        </ul>
      )}

      {description2 && <caption>{description2}</caption>}

      {manualReverify && (
        <Grid mt={1}>
          <TextInput
            data-testid="verification-input"
            value={userVerificationString}
            onChange={(value) => setUserVerificationString(value)}
            label={
              verificationHeading || (
                <p>
                  Please enter <strong>{verificationString}</strong> to confirm.
                </p>
              )
            }
          />
        </Grid>
      )}
      {children}
    </Modal>
  );
});

VeneerModal.displayName = "VeneerModal";

VeneerModal.propTypes = {
  title: PropTypes.string,
  description1: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  open: PropTypes.bool,
  onConfirm: PropTypes.func,
  onClose: PropTypes.func,
  showPrimary: PropTypes.bool,
  primaryLoading: PropTypes.bool,
  primaryText: PropTypes.string,
  showSecondary: PropTypes.bool,
  secondaryText: PropTypes.string,
  size: PropTypes.string,
  manualReverify: PropTypes.bool,
  verificationString: PropTypes.string,
  showHTMLContent: PropTypes.bool,
  htmlContent: PropTypes.func,
  descriptionList: PropTypes.array,
  maxWidth: PropTypes.string,
  destructive: PropTypes.bool,
  customId: PropTypes.string,
  icon1Type: PropTypes.oneOf(["warning"]),
  icon1Size: PropTypes.oneOf(["small", "medium", "large"]),
  description2: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  verificationHeading: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.elementType,
    PropTypes.array,
  ]),
  hasHTMLDescription: PropTypes.bool,
  disablePrimary: PropTypes.bool,
};

export default VeneerModal;
