import { createApi } from "@reduxjs/toolkit/query/react";
import RTKbaseQuery from "../../common/servicers/RTKbaseQuery";
import { HTTP_METHODS } from "../../common/constants";
import { setSelectedSessionHistoryMachine } from "./logSlice";

export const logsApi = createApi({
  reducerPath: "logsApi",
  baseQuery: RTKbaseQuery,
  endpoints: (builder) => ({
    getSessionHistoryLogs: builder.query({
      query: ({
        tenantId,
        organizationId,
        offset = 10,
        search,
        orderBy,
        pageNumber = 1,
      }) => {
        const params = new URLSearchParams();

        params.append("pageSize", offset);
        params.append("pageNumber", pageNumber);

        if (orderBy) {
          params.append("orderBy", orderBy);
        }

        if (search) {
          params.append("search", search);
        }

        return {
          url: `/organizations/${organizationId}/tenants/${tenantId}/users/pcoipSessionHistory?${params.toString()}`,
          method: HTTP_METHODS.GET,
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          const sessionHistoryMachine = data?.find(
            (log) => log.pcoipSessionId === args.pcoipSessionId,
          );

          if (sessionHistoryMachine) {
            dispatch(setSelectedSessionHistoryMachine(sessionHistoryMachine));
          }
        } catch (error) {
          console.error("Error onQueryStarted", error);
        }
      },
      transformResponse: (response) => {
        const formatRows = (payload) => {
          return payload.map((item) => {
            return {
              machineName: item.machineName,
              loggedInUser: item.loggedInUsers[0] ?? "",
              approximateDurationMs: item.approximateDurationMs,
              startedOn: item.startedOn,
              lastUpdatedOn: item.lastUpdatedOn,
              sessionState: item.sessionState,
              pcoipSessionId: item.pcoipSessionId,
              machineId: item.machineId,
            };
          });
        };

        const formattedData = formatRows(response || []);
        return formattedData;
      },
      providesTags: (result, error, arg) => [
        { type: "SessionHistoryLogs", id: arg.tenantId },
      ],
    }),
  }),
});

export const { useGetSessionHistoryLogsQuery } = logsApi;
