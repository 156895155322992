import { useTheme } from "@mui/material/styles";
import React from "react";

export const NoDataBanner = () => {
  const theme = useTheme();
  const primaryColor = theme.palette.primary.main;
  const blobColor = theme.palette.background.stroke;
  const backgroundColor = theme.palette.background.container;
  const foregroundColor = theme.palette.foreground.default;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="90"
      height="90"
      fill="none"
      viewBox="0 0 90 90"
    >
      <circle cx="45" cy="45" r="39" fill={blobColor}></circle>
      <path
        fill={primaryColor}
        d="M68.18 21.251c0 4.139-10.112 7.494-23.186 7.494-13.075 0-23.178-3.355-23.178-7.494 0-4.138 10.103-7.493 23.178-7.493 13.074 0 23.186 3.355 23.186 7.493z"
      ></path>
      <path
        fill={primaryColor}
        d="M68.18 21.251c0 4.139-10.112 7.494-23.186 7.494-13.075 0-23.178-3.355-23.178-7.494v29.877c.004 4.138 10.68 7.498 23.753 7.498a74.44 74.44 0 004.262-.123c9.24-.544 16.066-2.84 17.874-5.782.312-.508.475-1.035.475-1.575V21.25z"
      ></path>
      <path
        fill={primaryColor}
        d="M68.18 51.146c0 .54-.163 1.067-.475 1.575-1.808 2.943-8.634 5.238-17.874 5.782a68.278 68.278 0 01-1.82.083 74.44 74.44 0 01-2.442.04c-13.072 0-23.749-3.36-23.752-7.498V67.98c.523 3.118 11.445 5.779 23.746 5.779 13.074 0 22.617-2.871 22.617-5.779V51.146z"
      ></path>
      <path
        fill={backgroundColor}
        d="M68.18 21.251c0 4.139-10.112 7.494-23.186 7.494-13.075 0-23.178-3.355-23.178-7.494 0-4.138 10.103-7.493 23.178-7.493 13.074 0 23.186 3.355 23.186 7.493z"
      ></path>
      <path
        stroke={foregroundColor}
        strokeLinecap="round"
        d="M68.18 21.251c0 4.139-10.112 7.494-23.186 7.494-13.075 0-23.178-3.355-23.178-7.494m46.364 0c0-4.138-10.112-7.493-23.186-7.493-13.075 0-23.178 3.355-23.178 7.493m46.364 0V52.89M21.816 21.251v14.796m46.364-.03c0 4.14-9.536 7.48-22.611 7.48-13.024 0-23.67-3.334-23.752-7.45m0 0v-.049m0 .049v15.081m0 0c.003 4.138 10.68 7.498 23.752 7.498 2.657 0 5.217-.1 7.858-.372m-31.61-7.126V67.98c.523 3.118 11.445 5.779 23.746 5.779 1.286 0 3.848-.105 5.592-.17"
      ></path>
      <path
        fill={backgroundColor}
        stroke={backgroundColor}
        d="M76.5 67.258c0 6.627-5.373 12-12 12s-12-5.373-12-12c0-6.628 5.373-12 12-12s12 5.372 12 12z"
      ></path>
      <path
        fill={foregroundColor}
        d="M65.75 73.508a1.25 1.25 0 11-2.5 0 1.25 1.25 0 012.5 0z"
      ></path>
      <path
        stroke={foregroundColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M61.047 63.18c.166-1.901 2.928-3.843 5.44-2.206 1.234.863 1.742 3.102.764 4.324-.764 1.027-1.707 1.56-2.304 2.337-.597.777-.506 2.244-.506 2.244"
      ></path>
    </svg>
  );
};
