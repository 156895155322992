import React from "react";
import PropTypes from "prop-types";
import { Grid, styled } from "@mui/material";
import Tooltip from "../Tooltip";

const StyledGrid = styled(Grid)(({ theme, color }) => ({
  marginRight: "8px",
  color: color || theme.palette.text.primary,
}));

const InputTooltip = ({ tooltip, trailingIcon, color }) => {
  if (!tooltip && !trailingIcon) {
    return null;
  }

  return (
    <StyledGrid
      color={color}
      container
      direction="row"
      alignItems="center"
      sx={{
        width: "max-content",
      }}
    >
      <Grid item>
        <Tooltip content={tooltip} userSelect="all" />
      </Grid>

      {trailingIcon && <Grid item>{trailingIcon}</Grid>}
    </StyledGrid>
  );
};

InputTooltip.propTypes = {
  errors: PropTypes.object,
  name: PropTypes.string,
  helperText: PropTypes.string,
  tooltip: PropTypes.node,
  label: PropTypes.string,
  trailingIcon: PropTypes.node,
};

export default InputTooltip;
