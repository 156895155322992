import {
  IconCheckmarkCircle,
  IconInfo,
  IconUpdate,
  IconXCircle,
} from "@veneer/core";

const STATUS_MAP = {
  ["inSession"]: {
    type: "help",
    label: "In session",
    icon: <IconUpdate size={16} filled />,
  },
  ["sessionAttempted"]: {
    type: "warning",
    label: "Attempted",
    icon: <IconInfo size={16} filled />,
  },
  ["sessionFailed"]: {
    type: "negative",
    label: "Failed",
    icon: <IconXCircle size={16} filled />,
  },
  ["sessionCompleted"]: {
    type: "positive",
    label: "Completed",
    icon: <IconCheckmarkCircle size={16} filled />,
  },
  ["sessionEnded"]: {
    type: "neutral",
    label: "Ended",
    icon: <IconInfo size={16} filled />,
  },
};

export const mapSessionHistoryLogTagType = (status) => {
  return STATUS_MAP[status]?.type || "disabled";
};

export const mapSessionHistoryLogLabel = (status) => {
  return STATUS_MAP[status]?.label || "Unknown";
};

export const mapSessionHistoryLogIcon = (status) => {
  return STATUS_MAP[status]?.icon || undefined;
};
