import * as React from "react";

const SessionHistoryMachineHeaderIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="112"
    height="112"
    fill="none"
    viewBox="0 0 112 112"
  >
    <path
      fill="#EDEDF7"
      d="M9.94 49.011c-10.837-32.97.02-46.793 40.808-29.566s56.49 30.324 55.176 48.194c-1.313 17.87-47.705 33.682-64.783 32.274S20.777 81.982 9.94 49.01"
    ></path>
    <path
      fill="#fff"
      d="M26.73 32.959h57.54v37.796a2 2 0 0 1-2 2H28.73a2 2 0 0 1-2-2z"
    ></path>
    <path
      fill="#A2A5FB"
      d="M82.27 25H27.942a2 2 0 0 0-2 2v5.96h58.33V27a2 2 0 0 0-2-2"
    ></path>
    <path
      fill="#A2A5FB"
      stroke="#212121"
      d="M72.058 29.208c0 .558-.44.99-.958.99-.519 0-.958-.432-.958-.99 0-.557.44-.99.958-.99s.958.433.958.99ZM76.55 29.208c0 .558-.44.99-.958.99-.519 0-.958-.432-.958-.99 0-.557.439-.99.958-.99s.957.433.957.99Z"
    ></path>
    <path
      fill="#212121"
      stroke="#212121"
      d="M81.041 29.208c0 .558-.439.99-.957.99-.519 0-.958-.432-.958-.99 0-.557.44-.99.958-.99s.957.433.957.99Z"
    ></path>
    <path
      stroke="#212121"
      strokeLinecap="round"
      d="M31.065 72.51h51.054a2 2 0 0 0 2-2V27.48a2 2 0 0 0-2-2H28.377a2 2 0 0 0-2 2v33.833"
    ></path>
    <path
      fill="#A2A5FB"
      fillRule="evenodd"
      d="M88.98 67.997a34.05 34.05 0 0 0-5.664-17.675l-11.127 7.54a20.43 20.43 0 0 1 3.399 10.606 20.45 20.45 0 0 1-2.66 10.817l11.618 6.742a34.1 34.1 0 0 0 4.434-18.03M22.46 67.175c.359-6.126 2.353-12.029 5.758-17.04l10.276 7.59a20.3 20.3 0 0 0-3.454 10.224 20.4 20.4 0 0 0 2.236 10.574L26.188 84.8C23.392 79.406 22.1 73.3 22.46 67.175"
      clipRule="evenodd"
    ></path>
    <path
      fill="#212121"
      fillRule="evenodd"
      d="M28.328 50.782a33.304 33.304 0 0 0-2.066 33.203l10.441-5.612a21.27 21.27 0 0 1 1.361-20.815l-4.09-2.954zm10.476 7.09a20.46 20.46 0 0 0-1.047 20.836l-11.83 6.358q-.185-.352-.362-.707a34.11 34.11 0 0 1 2.688-34.866A33.6 33.6 0 0 1 39.878 39.25a33.24 33.24 0 0 1 15.958-3.902 33.25 33.25 0 0 1 15.877 4.224 33.6 33.6 0 0 1 11.975 11.322l.008.013.051.081a34.06 34.06 0 0 1 5.233 17.009 34.1 34.1 0 0 1-4.428 18.02l-.006.01-11.618-6.742a20.45 20.45 0 0 0 2.66-10.818 20.43 20.43 0 0 0-3.399-10.605 20.2 20.2 0 0 0-6.961-6.45 19.95 19.95 0 0 0-9.526-2.534 19.94 19.94 0 0 0-9.575 2.341 20.2 20.2 0 0 0-7.323 6.654m-4.38-3.922 4.176 2.827a20.95 20.95 0 0 1 7.16-6.262 20.7 20.7 0 0 1 9.95-2.433c3.465.035 6.867.94 9.9 2.634a20.96 20.96 0 0 1 7.033 6.406l9.956-6.45a32.8 32.8 0 0 0-11.267-10.403 32.47 32.47 0 0 0-15.504-4.125 32.46 32.46 0 0 0-15.582 3.81 32.8 32.8 0 0 0-11.352 10.002zm38.619 3.912c1.906 3.116 3.206 6.909 3.332 10.578a21.26 21.26 0 0 1-2.384 10.544l10.256 5.951a33.3 33.3 0 0 0 3.945-16.91 33.26 33.26 0 0 0-5.1-16.593z"
      clipRule="evenodd"
    ></path>
    <path
      fill="#A2A5FB"
      fillRule="evenodd"
      d="M51.165 64 70.082 48.08l-9.653 21.37c-.639 2.363-2.78 4.1-5.323 4.1-3.047 0-5.518-2.495-5.518-5.571 0-1.542.62-2.937 1.621-3.945z"
      clipRule="evenodd"
    ></path>
    <path
      fill="#212121"
      fillRule="evenodd"
      d="M70.322 47.765a.4.4 0 0 1 .119.482l-9.64 21.34c-.695 2.516-2.981 4.362-5.695 4.362-3.265 0-5.912-2.673-5.912-5.97 0-1.572.603-3.003 1.587-4.069a.4.4 0 0 1 .131-.216L69.83 47.776a.39.39 0 0 1 .492-.01M51.58 64.168a.4.4 0 0 1-.093.149 5.18 5.18 0 0 0-1.506 3.663c0 2.857 2.294 5.173 5.124 5.173 2.36 0 4.35-1.613 4.943-3.807a.4.4 0 0 1 .022-.06l8.935-19.78z"
      clipRule="evenodd"
    ></path>
  </svg>
);

export default SessionHistoryMachineHeaderIcon;
