import { Skeleton } from "@mui/material";
import { styled } from "@mui/system";
import clsx from "clsx";
import React from "react";
import { ROW_HEIGHT } from "../constants";

const PREFIX = "ShimmerLoader";

const classes = {
  linechart: `${PREFIX}-linechart`,
  summaryLarge: `${PREFIX}-summaryLarge`,
  text: `${PREFIX}-text`,
  textCenter: `${PREFIX}-textCenter`,
  textImage: `${PREFIX}-textImage`,
  pie: `${PREFIX}-pie`,
  pieWithData: `${PREFIX}-pieWithData`,
  fullHeight: `${PREFIX}-fullHeight`,
  shimmerItems: `${PREFIX}-shimmerItems`,
  table: `${PREFIX}-table`,
};

const Root = styled("div")(() => ({
  width: "100%",
  color: "grey",
  fontSize: "25px",
  display: "flex",
  [`& .${classes.shimmerItems}`]: {
    width: "100%",
    padding: "4px 10px",
  },
  [`& .${classes.linechart}`]: {
    height: "300px",
  },
  [`& .${classes.summaryLarge}`]: {
    height: "48px",
  },
  [`& .${classes.text}`]: {
    height: "25px",
    maxWidth: "100px",
    marginBottom: "6px",
    marginRight: "20px",
  },
  [`& .${classes.textCenter}`]: {
    height: "25px",
    maxWidth: "100px",
    marginBottom: "6px",
  },
  [`& .${classes.textImage}`]: {
    padding: "0px",
  },
  [`& .${classes.pie}`]: {
    margin: "auto",
    marginTop: "10%",
    width: "100%",
  },
  [`& .${classes.pieWithData}`]: {
    height: "220px",
    margin: "auto",
  },
  [`& .${classes.fullHeight}`]: {
    height: "100%",
  },
  [`& .${classes.table}`]: {
    height: `${ROW_HEIGHT * 10}px`,
  },
}));

export default function ShimmerLoader(props) {
  const { type, width, customStyles } = props;

  const getSkeleton = (type) => {
    switch (type) {
      case "table":
        return (
          <Skeleton
            variant="rectangular"
            className={clsx(classes.shimmerItems, classes.table)}
          />
        );
      case "content":
        return (
          <Skeleton
            variant="rectangular"
            className={clsx(classes.shimmerItems, classes.table)}
          />
        );
      case "lineChart":
        return (
          <Skeleton
            variant="rectangular"
            className={clsx(classes.shimmerItems, classes.linechart)}
          />
        );
      case "summaryLarge":
      case "text":
        return (
          <Skeleton
            variant="text"
            className={clsx(classes.shimmerItems, classes.text)}
            style={width ? { maxWidth: width } : {}}
          />
        );
      case "textCenter":
        return (
          <Skeleton
            variant="text"
            className={clsx(classes.shimmerItems, classes.textCenter)}
            style={width ? { maxWidth: width } : {}}
          />
        );
      case "largeText":
        return (
          <Skeleton
            variant="rectangular"
            className={clsx(classes.shimmerItems, classes.summaryLarge)}
            style={width ? { maxWidth: width } : {}}
          />
        );
      case "pie":
        return (
          <Skeleton
            variant="circular"
            className={clsx(classes.shimmerItems, classes.pie)}
          />
        );
      case "pieWithData":
        return (
          <Skeleton
            variant="circular"
            className={clsx(classes.pieWithData, classes.fullHeight)}
          />
        );
      default:
        break;
    }
  };

  return (
    <Root style={customStyles ? customStyles : {}}>{getSkeleton(type)}</Root>
  );
}
