import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { StepCard, Stepper, Typography } from "@neos/core";
import { useLDClient } from "launchdarkly-react-client-sdk";
import PropTypes from "prop-types";
import React, { useEffect, useMemo, useState } from "react";
import { Prompt, useHistory } from "react-router-dom";
import { WizardTag } from "../../dashboard/components/tags/WizardTag";
import { registeredDevicesFeaturedFlag } from "../utils";
import CommonModal from "./CommonModal";
import VeneerButton from "./VeneerButton";

const PREFIX = "VerticalStepper";

const classes = {
  root: `${PREFIX}-root`,
  resetContainer: `${PREFIX}-resetContainer`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.root}`]: {
    width: "100%",
  },
  [`& .${classes.resetContainer}`]: {
    padding: theme.spacing(3),
  },
  ["& .button-options .MuiButton-sizeMedium"]: {
    minWidth: "112px",
    width: "fit-content",
  },
}));

const mapHeadingToResourceName = (heading) => {
  switch (heading) {
    case "Tenant Details":
      return "tenant";
    default:
      return "resource";
  }
};

const VerticalStepper = React.forwardRef(
  (
    {
      showReset = true,
      showRedirection = false,
      completionMessage,
      handleRedirection,
      completionButtonTitle,
      stepErrors = [],
      onCancel,
      defaultCancelPath = "/admin",
      setErrors,
      azureAccountLinkingStatus,
      ...props
    },
    ref,
  ) => {
    const ldClient = useLDClient();
    const history = useHistory();
    const [activeStep, setActiveStep] = useState(
      props.lastSessionActiveStep | 0,
    );

    const [navigateAway, setNavigateAway] = useState(false);
    const [nextLocation, setNextLocation] = useState(defaultCancelPath);

    const [stepLoading, setStepLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [stepWithError, setStepWithError] = useState(null);
    const [successfulSteps, setSuccessfulSteps] = useState([]);

    const path = window.location.pathname;
    const isRDEnabled = registeredDevicesFeaturedFlag(ldClient);
    const shouldTakeUserAway =
      path === "/onboardingRegisteredDevices" && !isRDEnabled;

    useEffect(() => {
      if (shouldTakeUserAway) {
        setShowModal(true);
      }
    }, [shouldTakeUserAway, isRDEnabled]);

    let enabledStepsIndex = -1;

    useEffect(() => {
      if (Number.isInteger(props.lastSessionActiveStep)) {
        setActiveStep(props.lastSessionActiveStep);
      }
    }, [props.lastSessionActiveStep]);

    const steps = props.stepsMetadata || [];

    const shouldShowModal = () => {
      return (
        typeof steps[activeStep]?.hasPendingChanges === "function" &&
        steps[activeStep]?.hasPendingChanges(activeStep)
      );
    };

    const handleNext = async () => {
      const step = steps.filter((step) => !step.hide)[activeStep];
      const markStepAsSuccessful = (prevActiveStep) => {
        setSuccessfulSteps((prevSuccessfulSteps) => [
          ...prevSuccessfulSteps,
          prevActiveStep,
        ]);
      };
      const advanceStep = () => {
        setActiveStep((prevActiveStep) => {
          markStepAsSuccessful(prevActiveStep);

          if (stepWithError === prevActiveStep + 1) {
            setStepWithError(null);
          }

          return prevActiveStep + 1;
        });
      };
      const handleError = (e) => {
        const isValidToNextStep = !e?.error || e === undefined;
        if (isValidToNextStep && activeStep < steps.length - 1) {
          advanceStep();
        }
      };
      if (step.onStepSubmit) {
        setStepLoading(true);
        if (setErrors) {
          setErrors(activeStep, false);
        }
        try {
          const result = await step.onStepSubmit();
          handleError(result);
        } catch (error) {
          console.error("Error in step submission:", error);
        }
        setStepLoading(false);
      } else {
        advanceStep();
      }
    };

    const handleBack = () => {
      if (stepStatus(activeStep) === "error") {
        setStepWithError(activeStep);
      }
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
      setActiveStep(0);
    };

    const handleCancel = () => {
      if (!showModal && shouldShowModal()) {
        setShowModal(true);
        return;
      }

      if (nextLocation) {
        setNavigateAway(true);
        history.push(nextLocation.pathname);
      }

      history.push(nextLocation);

      if (onCancel) {
        onCancel();
      }
    };

    const handleRouteChange = (location) => {
      if (!navigateAway && shouldShowModal()) {
        setShowModal(true);
        setNextLocation(location);
        return false;
      }
      return true;
    };

    const hideModal = () => {
      setShowModal(false);
      setNextLocation("/admin");
    };

    const handleEnableContent = (index) => {
      const status = stepStatus(index);
      if (
        (index === stepWithError && status === "error") ||
        (index === activeStep && status === "loading")
      ) {
        return false;
      }
      if (index === activeStep) {
        return true;
      }
      return activeStep >= 0 && stepContent(index);
    };

    const getStepAttributes = (index) => {
      if (stepErrors[index]) {
        return { status: "error", color: "error", enableContent: true };
      }

      if (azureAccountLinkingStatus && index === 1) {
        return { status: "success", color: "success", enableContent: false };
      }

      if (successfulSteps.includes(index)) {
        return { status: "success", color: "success", enableContent: false };
      }

      if (index < activeStep) {
        setSuccessfulSteps((prev) =>
          prev.includes(index) ? prev : [...prev, index],
        );
        return { status: "success", color: "success", enableContent: false };
      }

      if (stepLoading && index === activeStep) {
        return { status: "loading", color: "loading", enableContent: false };
      }

      if (index === activeStep) {
        return { status: "active", color: "info", enableContent: true };
      }

      return { status: "pending", color: "pending" };
    };

    const stepAttributeToStatusMap = {
      error: "error",
      success: "completed",
      loading: "processing",
      active: "inProgress",
      pending: "notStarted",
    };

    useEffect(() => {
      if (navigateAway && nextLocation) {
        history.push(nextLocation.pathname);
      }
    }, [navigateAway, nextLocation, history]);

    const stepStatus = (index) => getStepAttributes(index).status;
    const stepColor = (index) => getStepAttributes(index).color;
    const stepContent = (index) => getStepAttributes(index).enableContent;

    const isFirstStep = useMemo(() => activeStep === 0, [activeStep]);

    const modalMessage = useMemo(() => {
      if (shouldTakeUserAway) {
        return "This feature is not enabled. Please click 'exit' to go back to the Tenants view.";
      }
      if (isFirstStep) {
        return `Are you sure you want to cancel adding a new ${mapHeadingToResourceName(steps[0].heading)}? Information you have entered so far will not be saved. Complete the first step to create a draft record you can complete later.`;
      }
      return "Are you sure you want to leave this screen? Unsaved progress will be lost.";
    }, [isFirstStep, steps, shouldTakeUserAway]);

    const commonModalActions = [
      {
        label: isFirstStep ? "Exit" : "Leave",
        main: true,
        context: "error",
        onClick: handleCancel,
        id: "modal-exit-button",
      },
    ];

    if (!shouldTakeUserAway) {
      commonModalActions.push({
        label: isFirstStep ? "Keep editing" : "Cancel",
        context: "info",
        onClick: hideModal,
        id: "modal-keep-editing-button",
      });
    }

    return (
      <Root
        className={classes.root}
        ref={ref}
        role="region"
        aria-labelledby="stepper-heading"
      >
        <Stepper hideConnector orientation="vertical" activeStep={activeStep}>
          {steps.map((stepMetaData, index) => {
            if (stepMetaData.hide) {
              return null;
            }
            enabledStepsIndex++;
            return (
              <StepCard
                color={stepColor(index)}
                enableContent={handleEnableContent(index)}
                onClickCancel={handleCancel}
                onClickNext={handleNext}
                onClickPrevious={handleBack}
                status={stepStatus(index)}
                title={stepMetaData.heading}
                key={stepMetaData.heading}
                customTag={
                  <WizardTag
                    status={stepAttributeToStatusMap[stepStatus(index)]}
                  />
                }
                disableButtonNext={
                  !stepMetaData.hideAction &&
                  (stepLoading ||
                    (stepMetaData.canMoveToNextStep !== undefined &&
                      !stepMetaData.canMoveToNextStep))
                }
                disableButtonPrev={
                  !stepMetaData.hideAction &&
                  (activeStep === 0 ||
                    stepLoading ||
                    (stepMetaData.canMoveToPreviousStep !== undefined &&
                      !stepMetaData.canMoveToPreviousStep))
                }
                buttonNextLabel={
                  enabledStepsIndex ===
                  steps.filter((step) => !step.hide).length - 1
                    ? stepMetaData.stepSubmitButtonLabel || "Submit"
                    : "Next"
                }
              >
                <Box mt={2} mb={2} data-testid={`step-content-${index}`}>
                  {props.getStepContent(index)}
                </Box>
              </StepCard>
            );
          })}
        </Stepper>
        {showReset && activeStep === steps.length && (
          <Paper
            square
            elevation={0}
            className={classes.resetContainer}
            role="alert"
            aria-live="assertive"
          >
            <Typography variant="body1">
              All steps completed - you&apos;re finished
            </Typography>
            <VeneerButton
              appearance="primary"
              onClick={handleReset}
              data-testid={"reset"}
            >
              Reset
            </VeneerButton>
          </Paper>
        )}
        {showRedirection &&
          activeStep === steps.filter((step) => !step.hide).length && (
            <Paper
              square
              elevation={0}
              className={classes.resetContainer}
              role="alert"
              aria-live="assertive"
            >
              <Typography variant="body1">{completionMessage}</Typography>
              <VeneerButton
                appearance="primary"
                onClick={handleRedirection}
                data-testid={"redirect"}
              >
                {completionButtonTitle}
              </VeneerButton>
            </Paper>
          )}
        <Prompt
          when={shouldShowModal()}
          message={(location) => handleRouteChange(location)}
        />
        <CommonModal
          title="Leave screen"
          open={showModal}
          onClose={shouldTakeUserAway ? () => {} : hideModal}
          actions={commonModalActions}
        >
          {modalMessage}
        </CommonModal>
      </Root>
    );
  },
);

VerticalStepper.displayName = "VerticalStepper";

VerticalStepper.propTypes = {
  stepsMetadata: PropTypes.array.isRequired,
  getStepContent: PropTypes.func.isRequired,
  showReset: PropTypes.bool,
  hideAction: PropTypes.bool,
  showRedirection: PropTypes.bool,
  completionMessage: PropTypes.string,
  completionButtonTitle: PropTypes.string,
  handleRedirection: PropTypes.func,
  confirmOnExit: PropTypes.bool,
  stepErrors: PropTypes.object,
  onCancel: PropTypes.func,
  defaultCancelPath: PropTypes.string,
};

export default VerticalStepper;
