import { Field, Form, FormSpy } from "react-final-form";
import { useSelector } from "react-redux";
import { DISPLAY_POOL_NAME_MESSAGE_RESTRICTION } from "../../admin-actions/adminActionsConstants";
import TextInput from "../../common/components/form/TextInput";
import { useOrgAndTenantId } from "../../common/hooks/useOrgAndTenantId";
import {
  checkExistingPool,
  composeValidators,
  required,
} from "../../common/validators";
import { validatePoolName } from "../onboardingUtils";

export default function PoolNameForm({
  onChange,
  initialValues,
  form = "poolNameForm",
  isRequired = true,
  onFormValidityChange = () => {},
}) {
  const { pools } = useSelector((state) => state.adminActions.pools);
  const { tenantId } = useOrgAndTenantId();
  const tenantPools = pools[tenantId] || [];

  const handleOnChange = (e, input) => {
    input.onChange(e);
  };

  const validate = (values) => {
    const errors = {};
    const value = values.name;

    const validators = isRequired
      ? [required, validatePoolName]
      : [validatePoolName];

    const poolNameError = composeValidators(...validators)(value);

    if (poolNameError) {
      errors.name = poolNameError;
    } else if (value) {
      const poolExists = checkExistingPool(tenantPools)(value);
      if (poolExists) {
        errors.name = poolExists;
      }
    }

    return errors;
  };

  return (
    <Form
      onSubmit={() => {}}
      initialValues={initialValues}
      validate={validate}
      render={(formProps) => (
        <form onSubmit={formProps.handleSubmit}>
          <Field
            name="name"
            id="poolName"
            label="Pool Name"
            render={({ input, meta }) => {
              const showError = Boolean(
                meta.error && (meta.touched || input.value),
              );

              return (
                <TextInput
                  {...input}
                  label="Pool name"
                  sx={{ width: "100%" }}
                  error={showError}
                  helperText={
                    showError
                      ? meta.error
                      : DISPLAY_POOL_NAME_MESSAGE_RESTRICTION
                  }
                  helperTextVisibility="visible"
                  onChange={(e) => handleOnChange(e, input)}
                />
              );
            }}
          />
          <FormSpy
            subscription={{ valid: true, values: true }}
            onChange={({ valid, values }) => {
              onFormValidityChange(valid);
              onChange(values, form);
            }}
          />
        </form>
      )}
    />
  );
}
