import PropTypes from "prop-types";
import React from "react";
import {
  mapSessionHistoryLogIcon,
  mapSessionHistoryLogLabel,
  mapSessionHistoryLogTagType,
} from "../machines/utils/sessionHistoryLogStatusMap.js";
import { TagIndicator } from "./TagIndicator";

export const SessionHistoryLogTag = ({ status }) => {
  const label = mapSessionHistoryLogLabel(status);
  const type = mapSessionHistoryLogTagType(status);
  const leadingIcon = mapSessionHistoryLogIcon(status);

  return <TagIndicator label={label} type={type} leadingIcon={leadingIcon} />;
};

SessionHistoryLogTag.propTypes = {
  status: PropTypes.oneOf([
    "inSession",
    "sessionAttempted",
    "sessionFailed",
    "sessionCompleted",
    "sessionEnded",
  ]),
};
