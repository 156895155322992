import { styled } from "@mui/material/styles";
import { IconCheckmarkCircle, IconWarning, TextBox } from "@veneer/core";
import primitives from "@veneer/primitives";
import PropTypes from "prop-types";
import React from "react";
import { getFormFieldError } from "../../utils";
import InputTooltip from "./InputTooltip";

const StyledTextBox = styled(TextBox)(
  ({ theme, isHeader, orangeHighlighted }) => ({
    "& label": {
      color: theme.palette.foreground.light,
      fontWeight: 400,
      whiteSpace: "normal",
    },
    "&.variant--success .vn-input": {
      borderColor: primitives.color.green7,
      "& .vn-input__icon svg": {
        fill: primitives.color.green7,
      },
    },
    "&.variant--error .vn-input": {
      borderColor: primitives.color.red7,
      "& .vn-input__icon svg": {
        fill: primitives.color.red7,
      },
    },
    ...(isHeader && {
      "& .vn-input input:focus": {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.grey[900],
      },
    }),
    ...(orangeHighlighted && {
      "& [class*='error']": {
        borderColor: primitives.color.darkOrange5,
      },
    }),
  }),
);

const TextInput = React.forwardRef((props, ref) => {
  const {
    errors,
    name,
    onChange = () => {},
    helperText,
    tooltip,
    label,
    className = "",
    variant,
    trailingIcon = null,
    autoComplete = "new-password",
    isHeader = false,
    orangeHighlighted,
    ...rest
  } = props;
  const fieldError = getFormFieldError(errors, name);

  const variantType = {
    success: {
      className: "variant--success",
      icon: <IconCheckmarkCircle />,
    },
    error: {
      className: "variant--error",
      icon: <IconWarning />,
    },
  };

  const customClassName =
    `${className} ${variantType[variant]?.className || ""}`.trim();

  return (
    <StyledTextBox
      ref={ref}
      error={!!fieldError}
      helperText={fieldError || helperText || ""}
      name={name}
      label={label}
      separateLabel
      className={customClassName}
      autoComplete={autoComplete}
      trailingButton={<InputTooltip tooltip={tooltip} />}
      onChange={onChange}
      trailingIcon={variantType[variant]?.icon || trailingIcon}
      isHeader={isHeader}
      orangeHighlighted={orangeHighlighted}
      {...rest}
    />
  );
});

TextInput.displayName = "TextInput";

TextInput.propTypes = {
  errors: PropTypes.object,
  name: PropTypes.string,
  helperText: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  tooltip: PropTypes.node,
  label: PropTypes.node,
  trailingIcon: PropTypes.node,
  variant: PropTypes.oneOf(["success", "error", "default"]),
  className: PropTypes.string,
  onChange: PropTypes.func,
};

export default TextInput;
