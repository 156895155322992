import { asyncWithLDProvider } from "launchdarkly-react-client-sdk";
import React, { useEffect, useState } from "react";
const CLIENT_SIDE_ID = import.meta.env.REACT_APP_LAUNCHDARKLY_CLIENT_SIDE_ID;

export const LaunchDarklyProvider = ({ children }) => {
  const [InitializedProvider, setInitializedProvider] = useState(
    () => () => null,
  );

  useEffect(() => {
    const initialize = async () => {
      try {
        const LDProviderComponent = await asyncWithLDProvider({
          clientSideID: CLIENT_SIDE_ID,
        });
        setInitializedProvider(() => LDProviderComponent);
      } catch (error) {
        console.error(error);
      }
    };

    initialize();
  }, []);

  const Provider = InitializedProvider;

  return <Provider>{children}</Provider>;
};
