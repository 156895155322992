import { Grid, useTheme } from "@mui/material";
import { Select } from "@veneer/core";
import PropTypes from "prop-types";
import React from "react";
import { Field, Form } from "react-final-form";
import { handleChange } from "../../admin-actions/pools/poolsUtils";

export default function AssignmentUsersForm({
  onChange,
  initialValues,
  options,
  loading = false,
  label = "Target users",
  description,
  formName = "assignmentUsersForm",
}) {
  const theme = useTheme();
  return (
    <Grid container spacing={2}>
      {description && (
        <Grid item xs={12} color={theme.palette.foreground.light}>
          <p>{description}</p>
        </Grid>
      )}
      <Grid item xs={12}>
        <Form
          onSubmit={() => {}}
          initialValues={initialValues}
          render={(formMeta) => {
            const { form } = formMeta;
            const valueAssignmentUser = formMeta.values.assignmentUser;

            const internalOnChange = ({ value: selectedValue }) => {
              const valueCopy = [...(valueAssignmentUser || [])];
              const isOptionSelected = valueCopy.includes(selectedValue);
              if (isOptionSelected) {
                const index = valueCopy.indexOf(selectedValue);
                valueCopy.splice(index, 1);
              } else {
                valueCopy.push(selectedValue);
              }
              handleChange("assignmentUser", valueCopy, form);
              if (onChange) {
                onChange({ assignmentUser: valueCopy }, formName);
              }
            };
            return (
              <form onSubmit={formMeta.handleSubmit}>
                <Grid container spacing={1}>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "24px",
                      "& label": {
                        color: theme.palette.foreground.light,
                      },
                    }}
                  >
                    <Field
                      name="assignmentUser"
                      render={() => (
                        <Select
                          multiple
                          onChange={internalOnChange}
                          onClear={() => {
                            handleChange("assignmentUser", [], form);
                            if (onChange) {
                              onChange({ assignmentUser: [] }, formName);
                            }
                          }}
                          value={valueAssignmentUser}
                          label={label}
                          options={options}
                          loading={loading}
                          isSearchable
                          separateLabel
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </form>
            );
          }}
        />
      </Grid>
    </Grid>
  );
}

AssignmentUsersForm.propTypes = {
  onChange: PropTypes.func,
  initialValues: PropTypes.object,
  options: PropTypes.array,
  form: PropTypes.string,
  loading: PropTypes.bool,
  label: PropTypes.string,
};
